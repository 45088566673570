
import { Module,VuexModule,getModule,Mutation,Action } from "vuex-module-decorators"
import store from "@/store/index"
import GroupHallStoreType, {GroupHallType, HotUpDateType} from "@/views/Group/GroupHall/Store/indexTypoe";

@Module({
    name:"GroupHall",
    store,
    dynamic: true,
})
export default class GroupHall extends VuexModule implements GroupHallStoreType{

    GroupHallHotList: any[] = [];
    get getGroupHallHotList(){ return this.GroupHallHotList }
    @Mutation
    SetGroupHallHotList(data: any[]): void {
        this.GroupHallHotList = data
    }

    GroupHallHotUpData: HotUpDateType = {
        pageNo: 1,
        pageSize: 15
    };
    get getGroupHallHotUpData(){ return this.GroupHallHotUpData }
    @Mutation
    SetGroupHallHotUpData(data: HotUpDateType): void {
        this.GroupHallHotUpData = data
    }

    GroupHallList: any[] = [];
    get getGroupHallList(){ return this.GroupHallList }
    @Mutation
    SetGroupHallList(data: any[]): void {
        this.GroupHallList = data
    }

    GroupHallUpData: GroupHallType = {
        pageNo: 1, pageSize: 15,status:0
    };
    get getGroupHallUpData(){ return this.GroupHallUpData }
    @Mutation
    SetGroupHallUpData(data: GroupHallType): void {
        this.GroupHallUpData = data
    }

}

export const GroupHallStore = getModule( GroupHall )
