import Storage from "@/util/Storage";
import {GroupSquare, HotGroup} from "@/Api/Group";
import {GroupHallStore} from "@/views/Group/GroupHall/Store";


// 热门组团
export function getHotGroup() {
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    return Promise.resolve(HotGroup({userId,token},GroupHallStore.getGroupHallHotUpData).then(res=>{
        return res.data
    }))
}


// 组团列表
export function getGroup() {
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    return Promise.resolve(GroupSquare({userId,token},GroupHallStore.getGroupHallUpData).then(res=>{
        return res.data
    }))
}
